import { j as e } from "./jsx-runtime-DmTjM30f.js";
import f from "styled-components";
import { c as $ } from "./clsx.m-BMVuQnLY.js";
import { i as M } from "./isExhaustive-RFXKPwPh.js";
import { A as X } from "./AvatarLabelGroup-C4zzKo4w.js";
import "./styles-CuuuEl0j.js";
import { T as b } from "./Typography-DPoSDQ1T.js";
import { getPaletteForeground as D, getSpacings as w, getRadii as C, getPaletteBackground as v, getPaletteBorder as T, getPaletteBase as z } from "./components/Theme/utils.es.js";
import { createPortal as W } from "react-dom";
import { Menu as h } from "@headlessui/react";
import { A as Y } from "./Avatar-B5kFEaeY.js";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { useRef as Z, useMemo as _ } from "react";
import "./CssBaseline-E-zwSHrJ.js";
import { I as H } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { Items as q } from "./components/Dropdown/style.es.js";
import { B as ee } from "./Button-iQ12_5TW.js";
import { u as te } from "./useDisclose-DpzoV9h_.js";
import { u as L } from "./useAppFloating-CuhQeRN4.js";
import { I as re } from "./IconButton-CnISFwqD.js";
const ne = f.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`, se = f.div`
  flex: 1;
  text-align: left;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`, A = f(H)`
  color: ${D("quarterary.default")};
`, G = (r) => {
  const {
    text: n,
    shortcut: t,
    leftIcon: s,
    rightIcon: o,
    className: d
  } = r, i = {
    width: 16,
    height: 16
  };
  return /* @__PURE__ */ e.jsxs(ne, { className: d, children: [
    s && /* @__PURE__ */ e.jsx(
      A,
      {
        ...i,
        ...s
      }
    ),
    /* @__PURE__ */ e.jsxs(se, { children: [
      /* @__PURE__ */ e.jsx(b, { variant: "text", size: "sm", weight: "medium", as: "span", className: "text", children: n }),
      t && /* @__PURE__ */ e.jsx(b, { variant: "text", size: "xs", weight: "regular", as: "span", color: "tertiary", children: t })
    ] }),
    o && /* @__PURE__ */ e.jsx(
      A,
      {
        ...i,
        ...o
      }
    )
  ] });
}, oe = f.button`
  margin: ${w("xxs")} ${w("sm")};

  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${C("sm")};

  padding: 9px 10px;

  border: none;

  color: ${D("quarterary.default")};

  background-color: ${v("primary.default")};

  overflow: hidden;

  outline: none;

  background-color: ${(r) => r.$isActive ? v("primary.hover") : "inherit"};

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: ${v("primary.hover")};
  }
`, V = (r) => {
  const {
    selected: n,
    disabled: t,
    onClick: s,
    className: o,
    ...d
  } = r, i = $(
    {
      selected: n,
      disabled: t
    },
    o
  );
  return /* @__PURE__ */ e.jsx(h.Item, { disabled: t, children: ({ active: p }) => /* @__PURE__ */ e.jsx(
    oe,
    {
      onClick: s,
      $isActive: p,
      className: i,
      children: /* @__PURE__ */ e.jsx(G, { ...d })
    }
  ) });
}, ie = f.div`
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  
  padding: 4px 0;
    
  &:not(:first-child) {
    border-top: 1px solid ${T("secondary")};
  }
`, ae = (r) => {
  const {
    className: n,
    children: t,
    ...s
  } = r;
  return /* @__PURE__ */ e.jsx(ie, { ...s, className: n, children: t });
}, E = f.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  padding: 12px 16px;

  border-bottom: 1px solid ${T("secondary")};
`, Oe = (r) => {
  switch (r.variant) {
    case "avatar": {
      const {
        variant: n,
        avatar: {
          size: t,
          ...s
        },
        className: o,
        ...d
      } = r;
      return /* @__PURE__ */ e.jsx(E, { ...d, className: $(`variant-${n}`, o), children: /* @__PURE__ */ e.jsx(X, { ...s, size: t ?? "md" }) });
    }
    case "text": {
      const {
        variant: n,
        text: t,
        className: s,
        ...o
      } = r;
      return /* @__PURE__ */ e.jsx(E, { ...o, className: $(`variant-${n}`, s), children: /* @__PURE__ */ e.jsx(b, { variant: "text", size: "sm", weight: "semibold", as: "span", children: t }) });
    }
    default:
      M(r);
  }
}, le = (r) => {
  const {
    currentValue: n,
    selectedValue: t,
    isMultiple: s
  } = r;
  if (!s)
    return t;
  const o = n.split(",").filter((i) => !!i);
  return o.find((i) => i === t) ? [
    ...o
  ].filter((i) => i !== t).join(",") : [
    ...o,
    t
  ].join(",");
}, ce = f(ae)`
  &:not(:first-child) {
    border: none;
  }
  .selected {
    svg {
      color: ${D("brand.primary.default")};
    }
  }
`, de = f.input`
  display: none;
`, ue = (r) => {
  const {
    label: n,
    value: t,
    options: s,
    className: o,
    placement: d,
    renderOption: i,
    onChange: p,
    inputRef: g,
    isMultiple: x,
    ...I
  } = r, {
    isOpen: B,
    onClose: N,
    onOpen: k
  } = te(!1), {
    refs: j,
    floatingStyles: S
  } = L({
    floatingOptions: {
      placement: d,
      open: !1
    }
  }), c = Z(null), y = _(() => {
    if (x) {
      const a = [
        ...new Set(t.split(","))
      ];
      if (a.length === 1) {
        const [m] = a, u = s.find((P) => P.value === m);
        return u ? `: ${u.label}` : "";
      }
      if (a.length > 1)
        return ` (${a.length})`;
    }
    const l = s.find((a) => a.value === t);
    return l ? `: ${l.label}` : "";
  }, [
    t,
    s
  ]), F = s.length === 0, J = typeof i < "u", K = (l) => x ? t.split(",").findIndex((m) => m === l) !== -1 : l === t, O = (l, a) => {
    var u;
    const m = (u = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : u.set;
    if (c.current && m) {
      const P = le({
        currentValue: t,
        selectedValue: a,
        isMultiple: x
      });
      m.call(c.current, P);
      const U = new Event("input", {
        bubbles: !0
      });
      c.current.dispatchEvent(U);
    }
    x && l.preventDefault();
  }, Q = () => {
    B ? N() : k();
  };
  return /* @__PURE__ */ e.jsxs(h, { as: ce, children: [
    /* @__PURE__ */ e.jsx(
      h.Button,
      {
        as: ee,
        className: o,
        ref: j.setReference,
        onClick: Q,
        ...I,
        children: typeof n == "string" ? /* @__PURE__ */ e.jsxs(b, { size: "sm", color: "none", weight: "semibold", children: [
          n,
          y
        ] }) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
          n,
          /* @__PURE__ */ e.jsx(b, { size: "sm", color: "none", weight: "semibold", children: y })
        ] })
      }
    ),
    /* @__PURE__ */ e.jsx(
      de,
      {
        ref: (l) => {
          typeof g == "function" ? g(l) : g && (g.current = l), c.current = l;
        },
        value: t,
        onChange: p
      }
    ),
    W(
      /* @__PURE__ */ e.jsx(q, { style: S, ref: j.setFloating, children: F ? /* @__PURE__ */ e.jsx(V, { disabled: !0, text: "No data" }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: s.map(({ label: l, value: a }) => {
        const m = K(a);
        return J ? /* @__PURE__ */ e.jsx(
          h.Item,
          {
            as: "div",
            onClick: (u) => O(u, a),
            children: ({ active: u }) => i({ label: l, value: a, isSelected: m, isActive: u })
          },
          a
        ) : /* @__PURE__ */ e.jsx(
          V,
          {
            text: l,
            selected: m,
            onClick: (u) => O(u, a),
            rightIcon: m ? { variant: "check", color: "red" } : void 0
          },
          a
        );
      }) }) }),
      document.body
    )
  ] });
}, R = f(h.Button)`
  &:not(.disabled) {
    cursor: pointer;
  }

  padding: 0;
  border-style: none;
  background: ${v("primary.default")};

  &.variant-avatar {
    color: ${z("black")};

    &:hover {
      color: ${z("black")};
    }

    outline: none;
  }

  &.variant-select {
    padding: ${w("md")} ${w("lg")};
    border-radius: ${C("sm")};

    &:hover {
      background: ${v("primary.hover")};
    }
  }

  &.variant-menu {
    border-radius: ${C("full")};
    
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
    &:hover {
      box-shadow: 0 0 0 4px rgba(152, 162, 179, 0.14);
    }
  }

  width: ${(r) => r.$isFullWidth ? "100%" : ""};
`, ze = (r) => {
  const {
    variant: n,
    disabled: t,
    children: s,
    className: o,
    fullWidth: d,
    placement: i = "bottom-end"
  } = r, {
    refs: p,
    floatingStyles: g
  } = L({
    floatingOptions: {
      placement: i
    }
  }), x = $(`variant-${n}`, o), I = (c) => {
    const {
      size: y = "md",
      ...F
    } = c.avatarProps;
    return /* @__PURE__ */ e.jsx(
      R,
      {
        ref: p.setReference,
        className: x,
        disabled: t,
        children: /* @__PURE__ */ e.jsx(Y, { size: y, ...F })
      }
    );
  }, B = () => /* @__PURE__ */ e.jsx(
    R,
    {
      ref: p.setReference,
      className: x,
      disabled: t,
      children: /* @__PURE__ */ e.jsx(H, { variant: "kebab" })
    }
  ), N = (c) => /* @__PURE__ */ e.jsx(
    R,
    {
      className: x,
      ref: p.setReference,
      $isFullWidth: d,
      disabled: t,
      children: /* @__PURE__ */ e.jsx(G, { ...c.selectProps })
    }
  ), k = (c) => /* @__PURE__ */ e.jsx(
    ue,
    {
      placement: i,
      ...c.filterSelectProps
    }
  ), j = (c) => /* @__PURE__ */ e.jsx(
    h.Button,
    {
      ref: p.setReference,
      as: re,
      ...c.iconButtonProps
    }
  ), S = () => {
    switch (n) {
      case "avatar":
        return I(r);
      case "menu":
        return B();
      case "select":
        return N(r);
      case "filter":
        return k(r);
      case "icon":
        return j(r);
      default:
        M(n);
    }
  };
  return /* @__PURE__ */ e.jsxs(h, { children: [
    S(),
    n === "filter" ? void 0 : /* @__PURE__ */ e.jsx(e.Fragment, { children: W(
      /* @__PURE__ */ e.jsx(
        q,
        {
          ref: p.setFloating,
          style: g,
          $isFullWidth: d,
          children: s
        }
      ),
      document.body
    ) })
  ] });
};
export {
  V as D,
  ae as a,
  Oe as b,
  ze as c,
  ue as d,
  G as e
};
